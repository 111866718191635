<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE COMUNICACIONES AMBIENTALES
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-paste fa-3x"></i> <br>
                            <span class="lg-numero">{{listaComunicacionesAmb.length}}</span>
                            <br>
                            <span class="text-muted">Comunicaciones ambientales</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Comunicaciones Ambientales</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('004-14001-COM','c') == 1" cols="6" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarComunicacionAmb = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva comunicación
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button @click="generarInforme()" block size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-cloud-download-alt fa-3x pb-1"></i><br>Descargar comunicaciones ambientales
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Comunicaciones Ambientales registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaComunicacionesAmb" :fields="cabecera" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('004-14001-COM','u') == 1" @click="abrirModalActualizarComunicacionAmb(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('004-14001-COM','d') == 1" @click="eliminarComunicacion(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalRegistrarComunicacionAmb">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva comunicación ambiental </span>
            </h6>
            <CButtonClose @click="modalRegistrarComunicacionAmb = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarComunicacionAmb)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input v-model="datosComunicacionAmb.fecha" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6"></b-col>
                    <b-col md="12">
                        <validation-provider name="tipo comunicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo comunicación:" class="mb-2">
                                <b-form-input v-model="datosComunicacionAmb.tipoComunicacion" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el tipo comunicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="destinatario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Destinatario:" class="mb-2">
                                <b-form-input v-model="datosComunicacionAmb.destinatario" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el destinatario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarComunicacionAmb = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalActualizarComunicacionAmb">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar comunicación ambiental </span>
            </h6>
            <CButtonClose @click="modalActualizarComunicacionAmb = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarComunicacionAmb)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input v-model="datosActualizarComunicacionAmb.fecha" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6"></b-col>
                    <b-col md="12">
                        <validation-provider name="tipo comunicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo comunicación:" class="mb-2">
                                <b-form-input v-model="datosActualizarComunicacionAmb.tipoComunicacion" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el tipo comunicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="destinatario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Destinatario:" class="mb-2">
                                <b-form-input v-model="datosActualizarComunicacionAmb.destinatario" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el destinatario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarComunicacionAmb = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";

import expressions from 'angular-expressions';
import assign from "lodash.assign";

expressions.filters.lower = function (input) {
    // This condition should be used to make sure that if your input is
    // undefined, your output will be undefined as well and will not
    // throw an error
    if (!input) return input;
    return input.toLowerCase();
}

function angularParser(tag) {
    if (tag === '.') {
        return {
            get: function (s) {
                return s;
            }
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

export default {
    data() {
        return {
            disabled: false,
            modalRegistrarComunicacionAmb: false,
            modalActualizarComunicacionAmb: false,

            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "tipoComunicacion",
                    label: "Tipo comunicación",
                    class: "text-center",
                },
                {
                    key: "destinatario",
                    label: "Destinatario",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaComunicacionesAmb: [],
            datosComunicacionAmb: {
                idCliente: null,
                fecha: moment().format('YYYY-MM-DD'),
                tipoComunicacion: '',
                destinatario: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs

            },
            datosActualizarComunicacionAmb: {
                idComunicacionSGMA: null,
                idCliente: null,
                fecha: '',
                tipoComunicacion: '',
                destinatario: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs
            }
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        generarInforme(param) {
            let me = this;
            //me.swat('info', 'Generando reporte espere porfavor ...')
            me.disabled = true;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-logo-cliente", {
                        params: {
                            logo: JSON.parse(localStorage.settings).logo,
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    // me.listarNoConformidades();

                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}plantillas/plantilla-comunicaciones-ambientales.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [150, 60];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .setOptions({
                                parser: angularParser
                            })
                            .attachModule(imageModule);
                        doc.setData({
                            logo: response.data,
                            lista: me.listaComunicacionesAmb
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "comunicaciones-ambientales.docx");
                        //me.disabled = false;
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        abrirModalActualizarComunicacionAmb(param) {
            this.datosActualizarComunicacionAmb.idComunicacionSGMA = param.item.idComunicacionSGMA;
            this.datosActualizarComunicacionAmb.fecha = param.item.fecha;
            this.datosActualizarComunicacionAmb.tipoComunicacion = param.item.tipoComunicacion;
            this.datosActualizarComunicacionAmb.destinatario = param.item.destinatario;
            this.datosActualizarComunicacionAmb.ejercicio = param.item.ejercicio;
            this.modalActualizarComunicacionAmb = true;
        },
        registrarComunicacionAmb() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-comunicacion-amb",
                    me.datosComunicacionAmb, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarComunicacionAmb = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarComunicaciones();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarComunicacionAmb() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-comunicacion-amb",
                    me.datosActualizarComunicacionAmb, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarComunicacionAmb = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarComunicaciones();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalRegistrarComunicacionAmb() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosComunicacionAmb.fecha = moment().format('YYYY-MM-DD');
            this.datosComunicacionAmb.tipoComunicacion = '';
            this.datosComunicacionAmb.destinatario = '';
        },
        resetModalActualizarComunicacionAmb() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        listarComunicaciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-comunicaciones-amb", {
                        params: {
                            idCliente: me.datosComunicacionAmb.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs
                        },
                    }
                )
                .then(function (response) {
                    me.listaComunicacionesAmb = response.data,
                        me.totalRows = me.listaComunicacionesAmb.length
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarComunicacion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la comunicación ambiental?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-comunicacion-amb", {
                                idComunicacionSGMA: param.item.idComunicacionSGMA
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarComunicaciones();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalRegistrarComunicacionAmb: function (val) {
            if (val == false) {
                this.resetModalRegistrarComunicacionAmb();
            }
        },
        modalActualizarComunicacionAmb: function (val) {
            if (val == false) {
                this.resetModalActualizarComunicacionAmb();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosComunicacionAmb.idCliente = this.datosActualizarComunicacionAmb.idCliente = user.uidClient;
            this.listarComunicaciones();
        }
    }

}
</script>
